import React from "react";
import { AppImages } from "../../constants";
import { makeStyles } from "@material-ui/core";

const {
  homePageImg: { bgHome },
} = AppImages;

const useStyle = makeStyles((theme) => ({
  header_text: {
    fontSize: "46px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    "&>a": {
      color: theme.palette.primary.main,
    },
    "&>a:hover": {
      color: theme.palette.primary.dark
    }
  },
}));
const ProductIntegrityHeader = () => {
  const classes = useStyle();
  return (
    <div className="page-affiliate">
      <div
        className="section-affiliate"
        style={{
          backgroundImage: `url(${bgHome})`,
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col">
              <div className={classes.header_text}>
                HealthLOQ is thrilled to announce the launch of the
                <br />
                <a href="https://grmalliance.org/product-integrity-program/" target="_blank">
                  <b>GRMA Product Integrity Program!</b>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductIntegrityHeader;
